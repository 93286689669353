import { useStorage } from '@vueuse/core';

// Store constants

export const GENERAL_STORE_KEY  = 'com.synergizer.planner.general';
export const PREFS_STORE_KEY    = 'com.synergizer.planner.setup';

// Global state

export const langs = [];
export const lang  = storePref('locale', 'bg');

export const store = useStorage(PREFS_STORE_KEY, {
  timeline: {
    start: null,
    end: null
  },
  prefs: {
    snapStep: 1,
    lockBars: 'none',
    collapseEmptyRows: false
  }
});

// Store particular prefs

export function storePref(id, defaultVal) {
  const key = PREFS_STORE_KEY + '.' + id;
  return useStorage(key, defaultVal);
}